import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isValidPhoneNumber } from 'libphonenumber-js';

export function matchFieldsValidator(controlName: string, confirmControlName: string): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    if (!form) {
      return null;
    }
    const control = form.get(controlName);
    const confirmControl = form.get(confirmControlName);

    if (control?.value !== confirmControl?.value) {
      const mismatchError: ValidationErrors = { mismatch: true };
      confirmControl?.setErrors(mismatchError);
      return mismatchError;
    }
    return null;
  };
}

export function requireEither(...formControlsNames: string[]): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    const isAllFieldsEmpty = formControlsNames.every((controlName: string) => {
      const control = form.get(controlName);

      if (!control) return true;

      if (control.value && typeof control.value === 'object') {
        return Object.values(control.value).every((v) => !v);
      }

      return !control.value;
    });

    const error = isAllFieldsEmpty ? { requireEither: true } : null;

    formControlsNames.forEach((controlName) => {
      form.get(controlName)?.setErrors(error);
    });

    return error;
  };
}

export function phoneNumberValidator() {
  return (control: AbstractControl) => {
    if (control.value && !isValidPhoneNumber(control.value)) {
      return { phoneNumber: true };
    }

    return null;
  };
}
